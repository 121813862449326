p {
    margin: 0;
    padding: 0;
    margin-bottom: 0.2rem;
    align-self: flex-start;
}

p.you {
    font-size: 0.9rem;
    color: #999;
}

p.frank {
    font-size: 0.9rem;
    color: rgb(49, 135, 255);
}