.login-header {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 2%;
  }

  button.link {
    background: none;
    border: none;
    padding: 0;
    color: #069;
    text-decoration: underline;
    cursor: pointer;
  }

  button.center {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 50%;
  }

  .login {
    width: 80vw !important;
    background-color: white;
  }

  .mylists-header {
    display: flex;
    flex-direction: column;
    font-size: calc(10px + 2vmin);
    margin-top: 2%;
    margin-left: 3%;
    margin-right: 3%;
    min-height: 90vh;
  }

  p.mini{
    font-size: 0.75em;
    color: gray;
    margin-bottom: 0.1em;
  }
  p.emphasize{
    font-size: 1.3em;
    margin-bottom: 0.1em;
    align-self: center;
  }

button.edit{
    border-radius: 75px;
}