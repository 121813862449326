  
  .ChatArea {
    margin-top: 1%;
    display: flex;
    max-height: 90vh;
    flex-direction: column;
    justify-content: flex-start;
    overflow-y: auto;
  }
  
  .InputArea {
    margin-top: 85vh;
    width: 90vw;
  }
  
  .MessagesArea{
    flex: 'none';
    align-self: center;
    width: 90vw;
  }