.header {
    display: flex;
    flex-direction: column;
    font-size: calc(10px + 2vmin);
    margin-top: 2%;
    margin-left: 3%;
    margin-right: 3%;
    align-items: flex-start;
  }
  
  p.mini{
    font-size: 0.75em;
    color: gray;
    margin-bottom: 0.1em;
    text-align: left;
  }

  p.mini.center{
    text-align: center;
  }
  
  p.mini.bold{
    color: rgb(79, 78, 78);
    font-weight: bold;
  }

  .error-header{
    display: flex;
    flex-direction: column;
    font-size: calc(10px + 2vmin);
    align-items: center;
    justify-content: center;
    text-align: center;
    height: 90vh;
  }