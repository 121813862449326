canvas{
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
  z-index: -1;
}

.header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  text-align: center;
}

.content-container{
  width: 75vw;
  align-self: center;
}

.logo {
  height: 30vmin;
  pointer-events: none;
}

h1{
  color: black;
}

p{
  color:cadetblue;
}

@media (prefers-reduced-motion: no-preference) {
  .logo {
    animation: logo-pulse infinite 2s alternate;
  }
}

.App-link {
  color: #61dafb;
}

@keyframes logo-pulse {
  from {
    transform: scale(1);
  }
  to {
    transform: scale(0.90);
  }
}